<template>

    <form class="newsletter-form form">

        <div class="newsletter-form__intro">
            <slot name="intro" />
        </div>

        <div class="newsletter-form__form-fields">

            <label v-if="texts.nameLabel" class="form__label" for="newsletter-form__name" id="newsletter-form__name-label">{{ t('nameLabel') }}</label>
            <input class="form__input" type="text" id="newsletter-form__name" name="name" :disabled="isInputDisabled" :placeholder="t('namePlaceholder', '')" v-model="newsletterName" />

            <label v-if="texts.lastNameLabel" class="form__label" for="newsletter-form__last-name" id="newsletter-form__last-name-label">{{ t('lastNameLabel') }}</label>
            <input class="form__input" type="text" id="newsletter-form__last-name" name="last-name" :disabled="isInputDisabled" :placeholder="t('lastNamePlaceholder', '')" v-model="newsletterLastName" />


            <label v-if="texts.emailLabel" class="form__label" for="newsletter-form__email" id="newsletter-form__email-label" :class="emailLabelClass">{{ t('emailLabel') }}</label>
            <input :class="['form__input', emailLabelClass]" type="email" id="newsletter-form__email" name="email" :disabled="isInputDisabled" :placeholder="t('emailPlaceholder', '')" v-model="newsletterEmail" />

        </div>

        <div class="newsletter-form__outro">

            <div class="form__checkbox-label"  id="newsletter-form__disclaimer">
                <input class="form__checkbox" type="checkbox" id="newsletter-form__disclaimer-checkbox" :disabled="isInputDisabled" v-model="newsletterDisclaimer" />
                <label class="text" for="newsletter-form__disclaimer-checkbox" v-html="t('disclaimerLabel')" />
            </div>

            <div v-if="success && !error" class="form__response-message form__success">
                <p>{{ t('formSuccessMessage') }}</p>
            </div>

            <div v-if="success && error" class="form__response-message form__error ">
                <p>{{ t(error) }}</p>
            </div>


            <sqace-button v-if="!success || error" id="newsletter-form__submit-button" @click.prevent="handleSubmit" variant="solid" style="primary" :loading="loading" :disabled="isButtonDisabled" icon-position="right">

                <template #loading-indicator><slot name="loading-icon" /></template>
                {{ t('submitButtonLabel') }}
            </sqace-button>
        </div>

    </form>

</template>

<script>
import { emailIsValid } from '@/utils/EmailValidator.js';

export default {

    name: 'NewsletterForm',

    props: {
        texts: Object,
    },

    data() {
        return {
            newsletterName: '',
            newsletterLastName: '',
            newsletterEmail: '',
            newsletterDisclaimer: false,

            success: false,
            loading: false,
            error: null,
        }
    },

    computed: {

        isButtonDisabled() {
            return !this.newsletterName || !this.newsletterEmail || this.emailInvalid || !this.newsletterDisclaimer;
        },

        isInputDisabled() {
            return this.loading || this.success && !this.error;
        },

        emailInvalid() {
            return !!this.newsletterEmail.trim().length && !emailIsValid(this.newsletterEmail);
        },

        emailLabelClass() {
            return {
                'form__input--invalid': this.newsletterEmail.length > 0 && !emailIsValid(this.newsletterEmail)
            }
        },
    },

    methods: {
        async handleSubmit() {

            if (this.isButtonDisabled) { return; }

            this.error = null;
            this.success = false;
            this.loading = true;

            var bodyFormData = new FormData();
            bodyFormData.set('payload', JSON.stringify({
                name: this.newsletterName,
                lastName: this.newsletterLastName,
                email: this.newsletterEmail,
                disclaimer: this.newsletterDisclaimer,
            }));


            const { data: csrfData } = await this.$axios.get('/actions/site-module/contact-form/get-csrf-token');

            bodyFormData.append(window.csrfTokenName, csrfData.csrf)

            const { data } = await this.$axios.post('/actions/site-module/newsletter/subscribe', bodyFormData);

            // const data = {
            //     // error: 'errorFormFieldRequired',
            //     success: true
            // };

            if (data.hasOwnProperty('error')) {
                this.error = data.error;
            }

            this.loading = false;
            this.success = true;
        },

        t(key, fallback) {
            if (!this.texts || !this.texts.hasOwnProperty(key)) {
                return fallback != null ? fallback : key;
            }

            return this.texts[key];
        },
    }

}
</script>
