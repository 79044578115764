<template>

    <component :is="element" @click="handleClick">
        <slot />
    </component>

</template>

<script>

export default {
    name: 'OverlayTrigger',

    inject: [ 'getOverlay' ],

    props: {
        element: {
            type: String,
            default: 'div'
        },
        name: {
            type: String,
            required: true
        }
    },


    created() {

        // const trigger = document.querySelector(`[data-overlay="${this.name.trim()}"`);
        // trigger.addEventListener('click', this.handleClick);
    },


    methods: {
        handleClick(event) {
            event.preventDefault();

            const overlayId = this.name.trim()
            const overlay = this.getOverlay(overlayId);

            if (overlay) {
                overlay.openOverlay();
            }
        }

    }
}
</script>
