<script setup>
import { ref, computed, inject } from 'vue';
import { useCssVar } from '@vueuse/core';
import { useResizeObserver } from '@vueuse/core';


const props = defineProps({
    data: { type: Object },
    topics: { type: Array },
})

const axios = inject('$axios');

const root = ref(null);
const content = ref(null);
const contentHeight = useCssVar('--content-height', root, { initialValue: '0' });

useResizeObserver(content, (entries) => {
    const entry = entries[0]
    const { blockSize } = entry.borderBoxSize[0];
    contentHeight.value = `${blockSize}px`;
});

const contactName = ref('');
const contactEmail = ref('');
const contactCompany = ref('');
const contactCountry = ref('');
const contactMessage = ref('');
const contactSubject = ref('');
const contactDisclaimer = ref(false);
const success = ref(false);
const loading = ref(false);
const error = ref(null);

const isInputDisabled = computed(() => loading.value || success.value && !error.value);
const isButtonDisabled = computed(() => !contactName.value || !contactEmail.value || !emailIsValid(contactEmail.value) || !contactSubject.value || !contactMessage.value || ! contactDisclaimer.value);
// const isButtonDisabled = computed(() => false);


const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

async function handleSubmit() {

    if (isButtonDisabled.value) { return; }

    error.value = null;
    success.value = false;
    loading.value = true;

    var bodyFormData = new FormData();
    bodyFormData.set('payload', JSON.stringify({
        name: contactName.value,
        email: contactEmail.value,
        country: contactCountry.value,
        company: contactCompany.value,
        topic: contactSubject.value,
        message: contactMessage.value,
        disclaimer: contactDisclaimer.value,
    }));

    try {

        const { data: csrfData } = await axios.get('/actions/site-module/contact-form/get-csrf-token');

        bodyFormData.append(window.csrfTokenName, csrfData.csrf)

        const { data } = await axios.post('/actions/site-module/contact-form/submit', bodyFormData);

    //    const data = {
    //         // error: 'errorFormFieldRequired',
    //         success: true
    //     };


        if (data.hasOwnProperty('error')) {
            error.value = data.error;
        }

        loading.value = false;
        success.value = true;

    } catch(e) {
        console.log(e)
        error.value = 'errorFormCouldNotSent';
        loading.value = false;
        success.value = true;
    }
};


function getInputAttributes(required) {
    return {
        disabled: isInputDisabled.value,
        required,
    };
}

</script>

<template>
    <form class="contact-form form">

        <div class="contact-form__form-row">
            <div class="contact-form__input--half">
                <label class="form__label" for="contact-form__name" id="contact-form__name-label">{{ data.nameLabel }}{{ data.nameRequired ? '*' : '' }}</label>
                <input class="form__input" type="text" id="contact-form__name" name="name" v-model="contactName" v-bind="getInputAttributes(data.nameRequired)" />
            </div>
            <div class="contact-form__input--half">
                <label class="form__label" for="contact-form__email" id="contact-form__email-label">{{ data.emailLabel }}{{ data.emailRequired ? '*' : '' }}</label>
                <input
                    :class="[
                        'form__input',
                        'contact-form__input--half',
                        contactEmail.length > 0 && !emailIsValid(contactEmail) ? 'form__input--invalid' : null
                    ]"
                    type="email" id="contact-form__email" name="email" v-model="contactEmail" v-bind="getInputAttributes(data.emailRequired)" />
            </div>
        </div>

        <div class="contact-form__form-row">
            <div class="contact-form__input--half">
                <label class="form__label" for="contact-form__company" id="contact-form__company-label">{{ data.companyLabel }}{{ data.companyRequired ? '*' : '' }}</label>
                <input class="form__input contact-form__input--half" type="text" id="contact-form__company" name="company" v-model="contactCompany" v-bind="getInputAttributes(data.companyRequired)" />
            </div>

            <div class="contact-form__input--half">

                <label v-if="data.topicsLabel" class="form__label" for="contact-form__topic" id="contact-form__topic-label">{{ data.topicsLabel }}{{ data.topicsRequired ? '*' : '' }}</label>
                <div class="select-input" id="contact-form__topic-select" :disabled="isInputDisabled">

                    <slot name="select-icon" />

                    <select :class="['form__select', {'form__select-invalid': !contactSubject }]" name="topic" id="contact-form__topic" v-model="contactSubject" :disabled="isInputDisabled">
                        <option v-if="data.topicsPlaceholder" value="" disabled selected hidden> {{ data.topicsPlaceholder }}</option>
                        <option v-for="topic in topics" :key="topic">{{ topic }}</option>
                    </select>
                </div>

            </div>
        </div>

        <div class="contact-form__form-row">
            <div class="contact-form__input">
                <label class="form__label" for="contact-form__message" id="contact-form__message-label">{{ data.messageLabel }}{{ data.messageRequired ? '*' : '' }}</label>
                <textarea class="form__textarea" name="message" id="contact-form__message" v-model="contactMessage" v-bind="getInputAttributes(data.messageRequired)"></textarea>
            </div>
        </div>

        <div class="contact-form__form-row">
            <div class="contact-form__input">
                <div class="form__checkbox-label" id="contact-form__disclaimer">
                    <input class="form__checkbox" type="checkbox" id="contact-form__disclaimer-checkbox" :disabled="isInputDisabled" v-model="contactDisclaimer" required />
                    <label class="text" for="contact-form__disclaimer-checkbox" v-html="data.disclaimerLabel" />
                </div>
            </div>
        </div>

        <div v-if="success && !error" class="form__response-message form__success">
            <p>{{ data.formSuccessMessage }}</p>
        </div>

        <div v-if="success && error" class="form__response-message form__error ">
            <p>{{ data[error] }}</p>
        </div>



        <div class="contact-form__form-row">

            <sqace-button v-if="!success || error" id="contact-submit-button" @click.prevent="handleSubmit" variant="solid" style="primary" :loading="loading" :disabled="isButtonDisabled" icon-position="right">
                <template #loading-indicator><slot name="loading-icon" /></template>
                {{ data.submitButtonLabel }}
            </sqace-button>

        </div>

    </form>
</template>
