import "./scss/global.scss";
import 'swiper/css';

import { createApp } from 'vue';
import axios from 'axios'

import svgIconLoader from "./plugins/svgIconLoader";
import { Swiper, SwiperSlide } from 'swiper/vue';

import { ExpandTransition, FadeTransition } from '@bam/transitions';
import { Accordion, AccordionPanel } from '@bam/accordion';
import BameHeader from '@bam/header'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as ANIMATIONS from './animations';


gsap.registerPlugin(ScrollTrigger);
window.ST = ScrollTrigger;

Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
    window.setTimeout(() => {
        ScrollTrigger.refresh();
    }, 0)
});


const components = import.meta.globEager('./components/**/*.vue')

const axiosInstance =axios.create({
    baseURL: '',
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': window.csrfTokenValue,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
     }
});


const app = createApp({

    data: () => {
        return {
            navigationOpen: false,
            overlays: {},
        };
    },

    mounted() {
        ANIMATIONS.initMediaContent();
        ANIMATIONS.initVideoZoom();
        ANIMATIONS.initCards();
    },

    provide() {
        return {
            registerOverlay: this.registerOverlay,
            deRegisterOverlay: this.deRegisterOverlay,
            getOverlay: this.getOverlay,
            $axios: axiosInstance,
        }
    },

    methods: {

        toggleNavigation() {
            this.navigationOpen = !this.navigationOpen;
        },

        registerOverlay(id, overlay) {
            this.overlays[id] = overlay;
        },

        deRegisterOverlay(id) {
            delete this.overlays[id];
        },

        getOverlay(id) {
            return this.overlays[id];
        },

    }
});

app.use(svgIconLoader);

// Register axios as global property
app.config.globalProperties.$axios = axiosInstance;



Object.entries(components).forEach(([ path, definition ]) => {
    const componentName = path.split('/').pop().replace(/\.\w+$/, '')
    app.component(componentName, definition.default)
});

app.component('bam-header', BameHeader),
app.component('expand-transition', ExpandTransition);
app.component('fade-transition', FadeTransition);
app.component('accordion', Accordion);
app.component('accordion-panel', AccordionPanel);

app.component('swiper', Swiper);
app.component('swiper-slide', SwiperSlide);

app.mount('#app');
