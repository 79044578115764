
export default {
    install: (app, options) => {

        const svgComponents = {};

        const svgs = import.meta.globEager("../../node_modules/@bam/icon/src/icons/**/*.svg");

        Object.entries(svgs).forEach(([path, definition]) => {
            let componentName = path
                .split("/")
                .pop()
                .replace(/\.\w+$/, "");

            // Replace _ with -
            componentName = componentName.replace(/_/g, "-");
            const chunks = componentName.split("-");

            for (var i = 0; i < chunks.length; i++) {
                chunks[i] = chunks[i].charAt(0).toUpperCase() + chunks[i].slice(1);
            }

            componentName = `BamIcon${chunks.join("")}`;

            svgComponents[componentName] = definition.default;
        });

        // Plugin code goes here
        for (const key in svgComponents) {
            app.component(key, svgComponents[key]);
        }
    },
};
