import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const init = () => {

    const videoElements = gsap.utils.toArray(".section-video .media");

    videoElements.forEach(function(elem) {

        elem.style.transformOrigin = "top center";

        gsap.set(elem, { scale: 1.5 });

        gsap.fromTo(elem,
            {
                scale: 1.5
            },
            {
                scrollTrigger: {
                    trigger: elem,
                    scrub: true,
                    start: 'top 80%',
                    end: 'center center',
                    invalidateOnRefresh: true
                },
                scale: 1
            }
        );
    });
}

export default init;




